

















































import { Component, Vue } from 'vue-property-decorator';

/**
 * CMSAdmin Component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue')
    }
})
export default class CMSAdmin extends Vue {
}
